import React, { useState, useContext, useEffect } from 'react'
import { PaymentContext } from '../Context/PaymentContext'
import Receipt from '../Components/Receipt/Receipt'
import { useHistory } from 'react-router-dom'
import Header from '../Components/Header/Header'
import { Notifier } from '../Components/Notifier/Notifier'
import moment from 'moment'

export const CountDown = () => {
    const history = useHistory()
    const { darkMode, lot, guid, expiredTime } = useContext(PaymentContext)
    const [showReceipt, setShowReceipt] = useState(false)
    const [darkModeStyle, setDarkModeStyle] = useState({
        globalContainer: 'global_container',
        color: '#585858',
        circleAnimation: 'circleAnimation',
        countDownAdj: 'countdown_adjustment'
    })
    const [notifications, setNotifications] = useState(null)
    const [countDown, setCountDown] = useState(moment.duration(0))
    const [orange, setOrange] = useState()
    const [orangeTxt, setOrangeTxt] = useState()
    const [verbageChange, setVerbageChange] = useState('Active Session')

    const [containerStyling, setContainerStyling] = useState({
        divStyle: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        buttonStyle: {
            marginTop: '30px',
            border: '1px solid #848484',
            fontSize: '13px',
            // border: '1px solid #FF4F4F',
            padding: '10px',
            borderRadius: '10px',
            margin: '0px 5px 0px 5px',
            boxShadow: '10px 10px 30px rgba(174, 174, 192, 0.4), -10px -10px 30px #FFFFFF'
        },
        buttonDiv: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around'
        }
    })
    const toggle = () => {
        setShowReceipt(!showReceipt)
    }


    // Set mutable variables for updates
    // These are the times updated times


    useEffect(() => {
        if (darkMode >= 1800 || darkMode <= 600) {
            setDarkModeStyle({
                globalContainer: 'global_container_dark',
                color: 'white',
                submitBtn: 'submitBtn_dark',
                circleAnimation: 'circleAnimation_dark',
                countDownAdj: 'countdown_adjustment_dark'
            })
            setContainerStyling({
                divStyle: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                },
                buttonStyle: {
                    marginTop: '30px',
                    border: '1px solid #EA4335',
                    fontSize: '13px',
                    // border: '1px solid #FF4F4F',
                    padding: '10px',
                    borderRadius: '10px',
                    margin: '0px 5px 0px 5px',
                    color: '#EA4335',
                    background: '#1E2834',
                    boxSizing: 'border-box',
                    boxShadow: '10px 10px 30px rgba(0, 0, 0, 0.6), -10px -10px 30px rgba(255, 255, 255, 0.12)'

                },
                buttonDiv: {
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-around'
                }
            })
        }

        const counter = setInterval(() => {
            const timeLeft = moment.duration(expiredTime.diff(moment()))
            setCountDown(timeLeft)

            if (timeLeft.as('minutes') < 20) {
                setOrange('countdown_adjustment_orange')
                setOrangeTxt('txtOrange')
            }
            if (timeLeft.as('minutes') <= 10) {
                setOrange('countdown_adjustment_red')
                setOrangeTxt('txtRed')
                setVerbageChange('Expiring Soon')

                // Send a test Message
            }
            if (timeLeft.as('seconds') <= 0) {
                setOrange('countdown_adjustment_red')
                setOrangeTxt('txtRed')
                setVerbageChange('Session Ended')

                fetch("https://osParking.pythonanywhere.com/expired-message", {
                    method: 'POST',
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        phone: localStorage.getItem('phone')
                    }),
                }).then((res) => res.json())
                    .then((data) => {
                        if (data.status === 400) {
                            setNotifications(`Error >>> ${data.message}`)
                        }
                        else {
                            setNotifications(null)
                        }
                    })
                // Send Text message

                clearInterval(counter);
            }
        }, 1000)

        return () => {
            clearInterval(counter)
        }
    }, [])

    return (
        <>
            <Header home={true} />
            <div style={{ overflowY: 'hidden' }} className={`${darkModeStyle.globalContainer} choose_lot ${orange}`}>
                <div style={containerStyling.divStyle}>
                    <div className='pulse_holder'>
                        <div className={darkModeStyle.circleAnimation}></div>
                        <div className='countdown_digits_holder'>
                            <div className={`${darkModeStyle.countDownAdj} ${orange}`}>
                                <h3 className={orangeTxt} style={{ marginBottom: '0px' }}>{verbageChange}</h3>
                                <div className='countdown_digits'>
                                    {countDown.days() > 0 ? (<span className={`digits ${orangeTxt}`}>{`${countDown.days()}d`}</span>) : ''}
                                    <span className={`digits ${orangeTxt}`}>{countDown.hours()}h</span>
                                    <span className={`digits ${orangeTxt}`}>{countDown.minutes()}m</span>
                                    <span className={`digits ${orangeTxt}`} >{countDown.seconds()}s</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    {showReceipt ? <Receipt close={toggle} /> : ''}
                    {notifications != null ? <Notifier notification={notifications} /> : ''}
                    <h4 className={orangeTxt} style={{ marginTop: '30%', color: darkModeStyle.color }}>Your Time Expires at: {expiredTime.format('LLL')}</h4>
                    <div style={containerStyling.buttonDiv}>
                        <span className={`${orange} ${orangeTxt}`} style={containerStyling.buttonStyle} onClick={() => { history.push('/' + lot) }}>Extend My Time</span>
                        <span className={`${orange} ${orangeTxt}`} onClick={toggle} style={containerStyling.buttonStyle}>View My Receipt</span>
                    </div>
                </div>
            </div>
        </>
    )
}
