import React, {useContext} from 'react'
import { useHistory } from 'react-router'
import { PaymentContext } from "../../../Context/PaymentContext"

/**
* @author
* @function DailyParking
**/

export const DailyParking = (props) => {
    const { lot } = useContext(PaymentContext)

    const history = useHistory()
    const daily_parking_page = () => { 
        history.push(lot + '/monthparking/')
    }
  return(
    <button className='global_circle_btns title' onClick={daily_parking_page}> 
    24Hr+
    </button>
   )

 }
 export default DailyParking;